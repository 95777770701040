<template>
  <v-container>
      <v-row>
        <v-col><v-btn @click="newProduct">Добавить</v-btn></v-col>
          <v-col align="right" @click="importProductsDialog = true"><v-btn>Импорт</v-btn></v-col>
      </v-row>
      <v-data-table :headers="headers" :items="products"
          :search="search"
          >
          <template v-slot:top>
              <v-text-field
                  v-model="search"
                  label="Поиск"
                  class="mx-4"
              ></v-text-field>
          </template>
          <template v-slot:header.actions="{ header }">
              {{ header.text }}
              <v-icon small @click.stop="openPrefs" class="mb-3">mdi-cog</v-icon>
          </template>
          <template v-slot:item.actions="{item}">
              <v-btn fab x-small text @click="skuProd=item, skuDialog = true"><v-icon small>mdi-sitemap</v-icon></v-btn>
              <v-btn fab x-small text @click="copyProduct(item)"><v-icon small>mdi-content-copy</v-icon></v-btn>
              <v-btn fab x-small text @click="editProduct(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
              <v-btn fab x-small text @click="deleteProduct(item)"><v-icon small>mdi-delete</v-icon></v-btn>
          </template>
      </v-data-table>
      <v-dialog v-model="editProductDialog" max-width="600px" scrollable>
          <v-form v-model="valid"
                  lazy-validation ref="form">
              <v-card>


                <v-card-title>Добавление товара</v-card-title>
                  <v-card-text>
                    <v-text-field label="Наименование" v-model="productTitle" required :rules="[v => !!v || 'Обязательное поле']"></v-text-field>
                    <v-text-field label="Цена"  @keypress="filter" v-model.number="productPrice" type="number"></v-text-field>
                    <template v-for="feature in features">
                        <v-text-field :required="feature.required" :rules="[v => (!!v || !feature.required) || 'Обязательное поле']" :key="feature.id" v-if="feature.type == 'string'" :label="feature.title" v-model="featuresValues[feature.id]"></v-text-field>
                        <v-text-field  @keypress="filter" :required="feature.required" :rules="[v => (!!v || !feature.required) || 'Обязательное поле']" :key="feature.id" v-else-if="feature.type == 'number'" :label="feature.title" type="number" v-model.number="featuresValues[feature.id]"></v-text-field>
                        <v-textarea :key="feature.id" :rules="[v => (!!v || !feature.required) || 'Обязательное поле']" v-else-if="feature.type == 'text'" :label="feature.title" type="number" v-model="featuresValues[feature.id]"></v-textarea>
                        <v-switch :key="feature.id" v-else-if="feature.type == 'boolean'" :label="feature.title" v-model="featuresValues[feature.id]"></v-switch>
                    </template>
                      <v-text-field label="Сортировка" v-model="productSort" required :rules="[v => !!v || 'Обязательное поле']"></v-text-field>
                      <v-row>
                          <v-col><v-switch label="Активен" v-model="productActive"></v-switch></v-col>
                        <v-col class="text-right">
                            <v-btn x-small text @click="featuresDialog=true"><v-icon left>mdi-pencil</v-icon>Редактировать поля</v-btn>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions><v-spacer></v-spacer><v-btn class="primary" @click="saveProduct">Сохранить</v-btn><v-btn text @click="editProductDialog = false">Отмена</v-btn></v-card-actions>
            </v-card>
          </v-form>
      </v-dialog>
      <v-dialog v-model="featuresDialog" scrollable max-width="600px">
              <v-card>
                  <v-card-title>Дополнительные поля<v-spacer></v-spacer><v-btn small text @click="editFeatureDialog = true"><v-icon left>mdi-plus</v-icon>добавить поле</v-btn></v-card-title>
                  <v-card-text>
                      <v-simple-table>
                          <template v-slot:default>
                              <thead>
                              <tr>
                                  <th class="text-left">
                                      ID
                                  </th>
                                  <th class="text-left">
                                      Название
                                  </th>
                                  <th class="text-left">
                                      Тип
                                  </th>
                                  <th class="text-center">
                                      Обязательное
                                  </th>
                                  <th class="text-center">
                                      Сортировка
                                  </th>
                                  <th class="text-center">
                                      Действия
                                  </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr
                                  v-for="item in features"
                                  :key="item.id"
                              >
                                  <td>{{ item.id }}</td>
                                  <td>{{ item.title }}</td>
                                  <td>{{ item.type }}</td>
                                  <td class="text-center"><v-icon color="green" v-if="item.required">mdi-check</v-icon></td>
                                  <td>{{ item.sort }}</td>
                                  <td>
                                      <v-btn fab x-small text @click="editFeature(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
                                      <v-btn fab x-small text @click="deleteFeature(item)"><v-icon small>mdi-delete</v-icon></v-btn>
                                  </td>
                              </tr>
                              </tbody>
                          </template>
                      </v-simple-table>
                  </v-card-text>
                  <v-card-actions><v-spacer></v-spacer><v-btn text @click="featuresDialog = false">Закрыть</v-btn></v-card-actions>
              </v-card>
      </v-dialog>
      <v-dialog v-model="editFeatureDialog" scrollable max-width="600px">
          <v-form v-model="validFeature"
                  lazy-validation ref="featureForm">
          <v-card>
              <v-card-title>Добавление поля</v-card-title>
              <v-card-text>
                <v-text-field label="Название" v-model="featureTitle" required :rules="[v => !!v || 'Обязательное поле']"></v-text-field>
                  <v-select v-model="featureType" required :rules="[v => !!v || 'Обязательное поле']" item-value="val" item-text="title" :items="[{title: 'Строка', val: 'string'}, {title: 'Число', val: 'number'}, {title: 'Текст', val: 'text'}, {title: 'Да/нет', val: 'boolean'}]">
                  </v-select>
                  <v-switch v-model="featureRequired" label="Обязательное поле"></v-switch>
                  <v-text-field v-model="featureDefault" v-if="(featureType && featureType=='boolean') || featureRequired" label="Значение по-умолчанию для существующих товаров" required :rules="[v => !!v || 'Обязательное поле']"></v-text-field>
                  <v-text-field label="Сортировка" v-model="featureSort" required :rules="[v => !!v || 'Обязательное поле']"></v-text-field>
              </v-card-text>
              <v-card-actions><v-btn @click="saveFeature" class="primary">Сохранить</v-btn><v-btn text @click="closeFeature">Отмена</v-btn></v-card-actions>
          </v-card>
          </v-form>
      </v-dialog>
      <v-dialog v-model="tablePrefDialog" scrollable max-width="600px">
          <v-card>
              <v-card-title>Настройка таблицы</v-card-title>
              <v-card-text>
                  <v-layout align-start justify-center>
                      <v-flex xs6 class="elevation-1 pa-3 ma-2">
                          <v-list dense class="mt-0 pt-0">
                              <v-subheader>
                                  Выбранные поля
                              </v-subheader>
                              <v-divider></v-divider>
                              <draggable v-model="headers" :options="{group:'columns'}" style="min-height: 10px">
                                  <template v-for="item in headers">
                                      <v-list-item :key="item.id">
                                          <v-list-item-content>
                                              {{item.text}}
                                          </v-list-item-content>
                                      </v-list-item>
                                  </template>
                              </draggable>
                          </v-list>
                      </v-flex>
                      <v-flex xs6 class="elevation-1 pa-3 ma-2">
                          <v-list dense class="mt-0 pt-0">
                              <v-subheader>
                                  Доступные поля
                              </v-subheader>
                              <v-divider></v-divider>
                              <draggable v-model="avalHeaders" :options="{group:'columns'}" style="min-height: 10px">
                                  <template v-for="item in avalHeaders">
                                      <v-list-item :key="item.id">
                                          <v-list-item-content>
                                              {{item.text}}
                                          </v-list-item-content>
                                      </v-list-item>
                                  </template>
                              </draggable>
                          </v-list>
                      </v-flex>
                  </v-layout>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="primary" @click="savePrefs">Сохранить</v-btn>
                  <v-btn text @click="tablePrefDialog = false">Отмена</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog scrollable v-model="skuDialog" max-width="900px">
          <v-card>
              <v-card-title>
                  Список SKU ({{skuProd.title}})
                  <v-btn class="ma-2 ml-lg-12 primary" small @click="editSkuDialog=true"><v-icon small left>mdi-plus</v-icon>Добавить</v-btn>

                  <v-spacer></v-spacer>
                  <v-btn class="ma-2"  x-small outlined  @click="SKUPropsDialog=true,skuPropsTable='barcodes'"><v-icon x-small left>mdi-barcode</v-icon>ШК</v-btn>
                  <v-btn class="ma-2" x-small outlined @click="SKUPropsDialog=true,skuPropsTable='colors'"><v-icon x-small left>mdi-palette</v-icon>Цвета</v-btn>
                  <v-btn class="ma-2" x-small outlined @click="SKUPropsDialog=true,skuPropsTable='sizes'"><v-icon x-small left>mdi-tag-text</v-icon>Размеры</v-btn>
              </v-card-title>
              <v-card-text>
                  <v-data-table :headers="headerSku" :items="skus"  disable-pagination hide-default-footer>
                      <template v-slot:item.actions="{item}">
                          <v-btn fab x-small text @click="printLabels(item)"><v-icon small>mdi-printer</v-icon></v-btn>
                          <v-btn fab x-small text @click="editSku(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
                          <v-btn fab x-small text @click="delSku(item)"><v-icon small>mdi-delete</v-icon></v-btn>
                      </template>
                  </v-data-table>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions><v-spacer></v-spacer><v-btn text @click="skuDialog = false" class="mr-5">Отмена</v-btn></v-card-actions>
          </v-card>
      </v-dialog>
      <v-dialog v-model="SKUPropsDialog" max-width="600px" scrollable>
          <v-card>
              <v-card-title>
                  <template v-if="skuPropsTable === 'colors'">Цвета</template>
                  <template v-else-if="skuPropsTable === 'sizes'">Размеры</template>
                  <template v-else-if="skuPropsTable === 'barcodes'">Штрихкоды</template>
                  <v-spacer></v-spacer>
                  <v-btn class="ma-2 ml-lg-12 primary" small @click="editSkuPropDialog=true"><v-icon small left>mdi-plus</v-icon>Добавить</v-btn>

              </v-card-title>
              <v-card-text>
                  <v-data-table :headers="[{text: 'ID', value: 'id'}, {text: 'Название', value: 'title'}, {text: 'Действия', value: 'actions'}]" :items="skuPropItems">
                      <template v-slot:item.actions="{item}">
                          <v-btn fab x-small text @click="editSkuPropDialog=true,skuPropItemId=item.id,skuPropTitle=item.title,skuPropSort=item.sort"><v-icon small>mdi-pencil</v-icon></v-btn>
                          <v-btn fab x-small text @click="deleteSkuProp(item)"><v-icon small>mdi-delete</v-icon></v-btn>
                      </template>
                  </v-data-table>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions><v-spacer></v-spacer><v-btn text @click="SKUPropsDialog = false" class="mr-5">Отмена</v-btn></v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="editSkuPropDialog" max-width="600px" scrollable>
          <v-form v-model="validSkuProp"
                  lazy-validation ref="skuPropForm">
              <v-card>
                  <v-card-title>Редактирование</v-card-title>
                  <v-card-text>
                      <v-text-field label="Название" v-model="skuPropTitle" required :rules="[v => !!v || 'Обязательное поле']"></v-text-field>
                      <v-text-field label="Сортировка" v-model="skuPropSort" required :rules="[v => !!v || 'Обязательное поле']"></v-text-field>
                  </v-card-text>
                  <v-card-actions><v-btn @click="saveSkuProp" class="primary">Сохранить</v-btn><v-btn text @click="editSkuProp = false">Отмена</v-btn></v-card-actions>
              </v-card>
          </v-form>
      </v-dialog>


      <v-dialog v-model="editSkuDialog" max-width="600px" scrollable>
          <v-form v-model="validSku"
                  lazy-validation ref="sku">
              <v-card>
                  <v-card-title>Редактирование SKU</v-card-title>
                  <v-card-text>
                    <v-select v-model="skuColor" :items="skuColors" item-text="title" item-value="id" label="Цвет" :rules="[v => !!v  || 'Обязательное поле']">
                    </v-select>
                      <v-select v-model="skuSize" :items="skuSizes" item-text="title" item-value="id" label="Размер" :rules="[v => !!v  || 'Обязательное поле']">
                    </v-select>
                      <template v-for="barcode in skuBarcodes">
                          <v-text-field :key="barcode.id" :label="barcode.title" v-model="skuBarcode[barcode.id]"></v-text-field>
                      </template>
                  </v-card-text>
                  <v-card-actions><v-btn @click="saveSku" class="primary">Сохранить</v-btn><v-btn text @click="editSkuDialog = false">Отмена</v-btn></v-card-actions>
              </v-card>
          </v-form>
      </v-dialog>

      <v-dialog v-model="printDialog" max-width="400">
          <v-card>
              <v-card-title>Печать</v-card-title>
              <v-card-text>
                  <v-select v-model="selectedReport" :items="reportsList" item-value="id" item-text="title">

                  </v-select>
                  <v-text-field v-model="printQty" type="number" label="Кол-во"></v-text-field>
              </v-card-text>
              <v-card-actions>
                  <v-btn class="primary" @click="printReport">Печать</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="importProductsDialog" max-width="500" scrollable>
          <v-card>
              <v-card-title>Импорт</v-card-title>
              <v-card-text>
                  <v-file-input :disabled="typeof fields == 'object' && fields != null" v-model="files" label="Выберите файл для импорта" accept=".xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></v-file-input>
                  <template v-if="fields">
                      <v-row align="center" justify="center" >
                          <v-col><v-checkbox label="Создавать товары" v-model="createProduct"></v-checkbox></v-col>
                          <v-col><v-checkbox label="Создавать SKU" v-model="createSku"></v-checkbox></v-col>
                      </v-row>
                      <v-row align="center" justify="center" >
                          <v-col>Колонка</v-col>
                          <v-col>Соответствие</v-col>
                          <v-col cols="1">
                              <v-tooltip slot="append" top>
                                  <template v-slot:activator="{ on }">
                                      <v-icon v-on="on" color="primary" dark>
                                          mdi-magnify
                                      </v-icon>
                                  </template>
                                  <span>Поиск в БД по этому полю</span>
                              </v-tooltip>
                          </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row v-for="(column, id) in columns" :key="id + column" align="center" justify="center" dense>
                          <v-col>{{column}}</v-col>
                          <v-col>
                              <v-select clearable @change="selectChange(id)" v-model="selectedFields[id]" :value="true" :items="fields" item-text="title" item-value="value"></v-select>
                          </v-col>
                          <v-col cols="1">
                              <v-checkbox v-model="searchFields" :value="id"></v-checkbox>
                          </v-col>
                      </v-row>
                  </template>
              </v-card-text>
              <v-card-actions>
                  <v-btn class="primary" @click="importProducts(0)">{{btnText}}</v-btn>

                  <v-btn text @click="importProductsDialog=false">Отмена</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-dialog v-model="importResult" v-if="typeof importResult == 'object'" max-width="700" scrollable>
          <v-card>
              <v-card-title v-if="!importProductsDialog">Результат импорта</v-card-title>
              <v-card-title v-else>Предварительный результат импорта</v-card-title>
              <v-card-text>
                  <v-expansion-panels>
                      <v-expansion-panel>
                          <v-expansion-panel-header>
                              Предупреждения ({{importResult.warnings.length}})
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                              <v-simple-table>
                                  <tbody>
                                    <tr v-for="item in importResult.warnings" :key="item">
                                        <td>{{item}}</td>
                                    </tr>
                                  </tbody>
                              </v-simple-table>
                          </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                          <v-expansion-panel-header>
                              Содздано товаров ({{importResult.createdProducts.length}})
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                              <v-simple-table>
                                  <tbody>
                                  <tr v-for="item in importResult.createdProducts" :key="item">
                                      <td>{{item}}</td>
                                  </tr>
                                  </tbody>
                              </v-simple-table>
                          </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                          <v-expansion-panel-header>
                              Обновлено товаров ({{importResult.updatedProducts.length}})
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                              <v-simple-table>
                                  <tbody>
                                  <tr v-for="item in importResult.updatedProducts" :key="item">
                                      <td>{{item}}</td>
                                  </tr>
                                  </tbody>
                              </v-simple-table>
                          </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                          <v-expansion-panel-header>
                              Содздано SKU ({{importResult.createdSku.length}})
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                              <v-simple-table>
                                  <tbody>
                                  <tr v-for="item in importResult.createdSku" :key="item">
                                      <td>{{item}}</td>
                                  </tr>
                                  </tbody>
                              </v-simple-table>
                          </v-expansion-panel-content>
                      </v-expansion-panel>

                      <v-expansion-panel>
                          <v-expansion-panel-header>
                              Обновлено SKU ({{importResult.updatedSku.length}})
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                              <v-simple-table>
                                  <tbody>
                                  <tr v-for="item in importResult.updatedSku" :key="item">
                                      <td>{{item}}</td>
                                  </tr>
                                  </tbody>
                              </v-simple-table>
                          </v-expansion-panel-content>
                      </v-expansion-panel>
                  </v-expansion-panels>
              </v-card-text>
              <v-card-actions>
                  <v-btn v-if="importProductsDialog" class="primary" @click="importProducts(1)">Выполнить импорт</v-btn>

                  <v-btn text @click="importResult=false">Отмена</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>

      <v-overlay :value="overlay" style="z-index:1100">

          <div>
              <v-alert v-if="!errorMessage" type="success" >
                  Успешно
              </v-alert>
              <v-alert v-else type="error" >
                  {{errorMessage}}
              </v-alert>
          </div>
          <div @click="overlay = false" style="position: fixed; top:0; bottom: 0; left: 0; right:0; background-color: rgba(255,255,255,0); opacity: 0.1"></div>

      </v-overlay>
      <v-overlay :value="progress" style="z-index:1000">
          <div class="text-center">
              <v-progress-circular
                  indeterminate
                  color="primary"
                  style="z-index:1001"
              ></v-progress-circular>
          </div>
      </v-overlay>
  </v-container>
</template>

<script>
    import axios from 'axios'
    import draggable from 'vuedraggable'
    import print from "print-js";
    // import qs from 'qs'

  export default {
      components: {
          draggable,
      },
      data: () => ({
          search: '',
          printQty: 1,
          selectedReport: null,
          reportsList: [],
          printDialog: false,
          overlay:false,
          progress: false,
          errorMessage: '',
          SKUPropsDialog: false,
          skuDialog: false,
          valid: true,
          validFeature: true,
          validSkuProp: true,
          validSku: true,
          editSkuPropDialog: false,
          editSkuDialog: false,
          tablePrefDialog: false,
          editProductDialog: false,
          featuresDialog: false,
          editFeatureDialog: false,
          productTitle: '',
          productActive: false,
          productPrice: 0,
          productSort: 100,
          productId: null,
          featureType: null,
          featureRequired: false,
          featureTitle: '',
          featureDefault: '',
          featureSort: 100,
          featureId: null,
          featuresValues: {},
          features: [],
          products: [],
          headers: [],
          avalHeaders: [],
          tmpheaders: {},
          skuColors: [],
          skuSizes: [],
          skuBarcodes: [],
          skus: [],
          headerSku: [],
          skuPropItems: [],
          skuProd: {},
          skuPropsTable: '',
          skuPropItemId: null,
          skuPropTitle: '',
          skuPropSort: 100,
          skuColor: null,
          skuSize: null,
          skuBarcode: {},
          skuId: null,
          importProductsDialog: false,
          files: null,
          fields: null,
          columns: null,
          searchFields: [],
          selectedFields: {},
          createProduct: false,
          createSku: false,
          importResult: false
      }),
      methods: {
          refresh() {
              let $this = this;
              this.progress = true;
              axios({
                  method: 'GET',
                  url: '/api/product/get'
              }).then(function (response) {
                  if (response.data.products) {
                      $this.products = response.data.products;
                      $this.progress = false;
                  }
                  else if (response.data.error) {
                      $this.errorMessage = response.data.error;
                      $this.overlay = true;
                      $this.progress = false;
                  }
              })
                  .catch(function () {
                      $this.errorMessage = 'Ошибка';
                      $this.overlay = true;
                      $this.progress = false;
                  });
          },
          selectChange(lastField) {
              for (let i in this.selectedFields) {
                  if (i != lastField) {
                      if (this.selectedFields[i] == this.selectedFields[lastField]) {
                          this.$nextTick(function() {
                              this.errorMessage = 'Ошибка! Поле уже используется';
                              this.$delete(this.selectedFields, lastField);
                              this.overlay = true;
                          });
                      }
                  }
              }
          },
          importProducts(commit) {
              if (this.files && Object.keys(this.selectedFields).length > 0) {
                  let formData = new FormData();
                  formData.append("files", this.files, this.files.name);
                  formData.append('searchFields', this.searchFields);
                  formData.append('createProduct', this.createProduct);
                  formData.append('createSku', this.createSku);
                  formData.append('commit', commit);
                  formData.append('selectedFields', Object.keys(this.selectedFields));
                  formData.append('selectedValues', Object.values(this.selectedFields));
                  let $this = this;
                  this.progress = true;
                  axios({
                      url: '/api/product/import',
                      method: 'POST',
                      data: formData
                  }).then(function (response) {
                      if (!response.data.error) {
                          $this.progress = false;
                          $this.importResult = response.data.importResult;
                          if (response.data.commit == '1') {
                              $this.refresh();
                              $this.importProductsDialog = false;
                          }

                      } else {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
              else if (this.files) {
                  let formData = new FormData();
                  formData.append("files", this.files, this.files.name);
                  let $this = this;
                  this.progress = true;
                  axios({
                      url: '/api/product/analyse',
                      method: 'POST',
                      data: formData
                  }).then(function (response) {
                      if (!response.data.error) {
                          $this.progress = false;
                          $this.columns = response.data.rows;
                          $this.fields = response.data.fields;
                      } else {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
          },
          printReport() {
              let $this = this;
              this.progress = true;
              axios({
                  url: '/api/report/labels',
                  method: 'POST',
                  responseType: 'blob',
                  data: {
                      type: 'skulabels',
                      report: this.selectedReport,
                      qty: this.printQty,
                      sku: this.skuId
                  }
              }).then(function (response) {
                  if (!response.data.error) {
                      $this.progress = false;
                      $this.skuId = null;
                      $this.selectedReport = null;
                      $this.printQty = 1;
                      $this.printDialog = false;
                      var blob = new Blob([response.data],{type:'application/pdf'});
                      var url = URL.createObjectURL(blob);
                      print(url);
                  }
                  else {
                      $this.errorMessage = response.data.error;
                      $this.overlay = true;
                      $this.progress = false;
                  }
              })
                  .catch(function () {
                      $this.errorMessage = 'Ошибка';
                      $this.overlay = true;
                      $this.progress = false;
                  });
          },
          printLabels(item) {
              let $this = this;
              this.progress = true;
              axios({
                  url: '/api/report/get',
                  method: 'GET',
              }).then(function (response) {
                  if (!response.data.error) {
                      $this.reportsList = response.data.reports;
                      $this.progress = false;
                      $this.printDialog = true;
                      $this.skuId = item.id;
                  }
                  else {
                      $this.errorMessage = response.data.error;
                      $this.overlay = true;
                      $this.progress = false;
                  }
              })
                  .catch(function () {
                      $this.errorMessage = 'Ошибка';
                      $this.overlay = true;
                      $this.progress = false;
                  });

          },
          delSku(item) {
              if (confirm('Вы действительно хотите удалить SKU?')) {
                  let $this = this;
                  this.progress = true;
                  axios({
                      url: '/api/sku/del',
                      params: {id: item.id, pid: this.skuProd.id},
                      method: 'GET',
                  }).then(function (response) {
                      if (response.data.skus) {
                          $this.skuColors = response.data.colors;
                          $this.skuSizes = response.data.sizes;
                          $this.skuBarcodes = response.data.barcodes;
                          $this.skus = response.data.skus;
                          $this.progress = false;
                          $this.errorMessage = '';
                          $this.overlay = true;
                      } else if (response.data.error) {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
          },
          editSku(item) {
              this.editSkuDialog=true;
              this.skuColor=item.color_id;
              this.skuSize=item.size_id;
              this.skuId = item.id;
              for (var key in item) {
                  if (key.startsWith('barcode_')) {
                      this.skuBarcode[key.replace('barcode_', '')] = item[key];
                  }
              }
          },
          saveSku() {
              if (this.$refs.sku.validate()) {
                  let $this = this;
                  this.progress = true;
                  axios({
                      url: '/api/sku/save',
                      params: {id: this.skuId, pid: this.skuProd.id},
                      method: 'POST',
                      data: {
                          color: this.skuColor,
                          size: this.skuSize,
                          product: this.skuProd.id,
                          barcodes: this.skuBarcode,
                      }
                  }).then(function (response) {
                      if (response.data.skus) {
                          $this.skuColors = response.data.colors;
                          $this.skuSizes = response.data.sizes;
                          $this.skuBarcodes = response.data.barcodes;
                          $this.skus = response.data.skus;
                          $this.editSkuDialog = false;
                          $this.progress = false;
                          $this.errorMessage = '';
                          $this.overlay = true;
                      }
                      else if (response.data.error) {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
          },
          deleteSkuProp (item) {
              if (confirm('Вы действительно хотите удалить "'+item.title+'"?')) {
                  let $this = this;
                  $this.progress = true;
                  axios({
                      url: '/api/skuprop/del',
                      params: {id: item.id, t: this.skuPropsTable, pid: this.skuProd.id},
                      method: 'GET'
                  }).then(function (response) {
                      if (response.data.skus) {
                          $this.skuColors = response.data.colors;
                          $this.skuSizes = response.data.sizes;
                          $this.skuBarcodes = response.data.barcodes;
                          $this.skus = response.data.skus;
                          $this.SKUPropsDialog = false;
                          $this.$nextTick(function () {
                              $this.SKUPropsDialog = true;
                          });
                          $this.editSkuPropDialog = false;
                          $this.progress = false;
                          $this.errorMessage = '';
                          $this.overlay = true;
                      } else if (response.data.error) {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
          },
          saveSkuProp () {
              if (this.$refs.skuPropForm.validate()) {
                  let $this = this;
                  this.progress = true;
                  axios({
                      url: '/api/skuprops/save',
                      params: {id: this.skuPropItemId, pid: this.skuProd.id},
                      method: 'POST',
                      data: {
                          title: this.skuPropTitle,
                          sort: this.skuPropSort,
                          t: this.skuPropsTable,
                      }
                  }).then(function (response) {
                      if (response.data.skus) {
                          $this.skuColors = response.data.colors;
                          $this.skuSizes = response.data.sizes;
                          $this.skuBarcodes = response.data.barcodes;
                          $this.skus = response.data.skus;
                          $this.SKUPropsDialog = false;
                          $this.$nextTick(function() {
                              $this.SKUPropsDialog = true;
                          });
                          $this.editSkuPropDialog = false;
                          $this.progress = false;
                          $this.errorMessage = '';
                          $this.overlay = true;
                      }
                      else if (response.data.error) {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
          },
          savePrefs() {
              let $this = this;
              $this.progress = true;
              axios({
                      url: '/api/prefs/save',
                      method: 'POST',
                      data: {
                          t: 'products',
                          items: this.headers
                      }
                  }).then(function (response) {
                      if (!response.data.error) {
                          $this.tmpheaders = {h1: $this.headers, h2: $this.avalHeaders};
                          $this.tablePrefDialog = false;
                          $this.progress = false;
                          $this.errorMessage = '';
                          $this.overlay = true;
                      }
                      else if (response.data.error) {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
          },
          openPrefs() {
              this.tablePrefDialog = true;
              this.tmpheaders = {h1: this.headers, h2: this.avalHeaders}
          },
          newProduct() {
              this.editProductDialog = true;
          },
          closeNewProduct () {
              this.$refs.form.resetValidation();
              this.productTitle = '';
              this.productActive = false;
              this.productPrice = 0;
              this.productSort = 100;
              this.productId = null;
              this.featuresValues = {};
              this.valid = true;
          },
          closeFeature () {
              this.$refs.featureForm.resetValidation();
              this.featureType = null;
              this.featureRequired = false;
              this.featureTitle = '';
              this.featureDefault = '';
              this.featureSort = 100;
              this.featureId = null;
              this.validFeature = true;
              this.editFeatureDialog = false;
          },
          deleteFeature (feature) {
              if (confirm('Вы действительно хотите удалить поле "'+feature.title+'"?'))
                  if (confirm('Все данные в существующих заказах, сохраненные в этом поле, будут удалены. Вы уверены что хотите удалить поле "'+feature.title+'"?')) {
                      let $this = this;
                      $this.progress = true;
                      axios({
                          url: '/api/feature/del',
                          params: {id: feature.id, t: 'products'},
                          method: 'GET'
                      }).then(function (response) {
                          if (response.data.features) {
                              $this.features = response.data.features;
                              $this.headers = response.data.headers;
                              $this.avalHeaders = response.data.avalHeaders;
                              $this.closeFeature();
                              $this.progress = false;
                              $this.errorMessage = '';
                              $this.overlay = true;
                          }
                          else if (response.data.error) {
                              $this.errorMessage = response.data.error;
                              $this.overlay = true;
                              $this.progress = false;
                          }
                      })
                          .catch(function () {
                              $this.errorMessage = 'Ошибка';
                              $this.overlay = true;
                              $this.progress = false;
                          });
                  }
          },
          editFeature (feature) {
              this.featureType = feature.type;
              this.featureRequired = feature.required;
              this.featureTitle = feature.title;
              this.featureDefault = '';
              this.featureSort = feature.sort;
              this.featureId = feature.id;
              this.validFeature = true;
              this.editFeatureDialog = true;
          },
          editProduct (product) {

              this.productTitle = product.title;
              this.productActive = product.active;
              this.productPrice = product.price;
              this.productSort = product.sort;
              this.productId = product.id;
              this.featuresValues = {};
              for (var key in product) {
                  if (key.startsWith('feature')) {
                      this.featuresValues[key.replace('feature_', '')] = product[key];
                  }
              }
              this.valid = true;
              this.editProductDialog = true;
          },
          copyProduct (product) {

              this.productTitle = product.title;
              this.productActive = product.active;
              this.productPrice = product.price;
              this.productSort = product.sort;
              this.featuresValues = {};
              for (var key in product) {
                  if (key.startsWith('feature')) {
                      this.featuresValues[key.replace('feature_', '')] = product[key];
                  }
              }
              this.valid = true;
              this.editProductDialog = true;
          },
          saveProduct () {
              if (this.$refs.form.validate()) {
                  let $this = this;
                  $this.progress = true;
                  axios({
                      url: '/api/product/save',
                      params: {id: this.productId},
                      method: 'POST',
                      data: {
                          title: this.productTitle,
                          active: this.productActive,
                          price: this.productPrice,
                          sort: this.productSort,
                          features: this.featuresValues
                      }
                  }).then(function (response) {
                      if (response.data.products) {
                          $this.products = response.data.products;
                          $this.editProductDialog = false;
                          $this.progress = false;
                          $this.errorMessage = '';
                          $this.overlay = true;
                      }
                      else if (response.data.error) {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
          },
          deleteProduct (product) {
              if (confirm('Вы действительно хотите удалить товар "'+product.title+'"?')) {
                  let $this = this;
                  $this.progress = true;
                  axios({
                      url: '/api/product/del',
                      params: {id: product.id},
                      method: 'GET'
                  }).then(function (response) {
                      if (response.data.products) {
                          $this.products = response.data.products;
                          $this.editProductDialog = false;
                          $this.progress = false;
                          $this.errorMessage = '';
                          $this.overlay = true;
                      }
                      else if (response.data.error) {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
              }
          },
          saveFeature () {
                  let $this = this;
                  if (this.$refs.featureForm.validate()) {
                      $this.progress = true;
                      axios({
                          url: '/api/feature/save',
                          params: {id: this.featureId},
                          method: 'POST',
                          data: {
                              t: 'products',
                              title: this.featureTitle,
                              type: this.featureType,
                              required: this.featureRequired,
                              sort: this.featureSort,
                              default: this.featureDefault,
                          }
                      }).then(function (response) {
                            if (response.data.features) {
                                $this.features = response.data.features;
                                $this.headers = response.data.headers;
                                $this.avalHeaders = response.data.avalHeaders;
                                $this.closeFeature();
                                $this.progress = false;
                                $this.errorMessage = '';
                                $this.overlay = true;
                            }
                            else if (response.data.error) {
                                $this.errorMessage = response.data.error;
                                $this.overlay = true;
                                $this.progress = false;
                            }
                          })
                          .catch(function () {
                              $this.errorMessage = 'Ошибка';
                              $this.overlay = true;
                              $this.progress = false;
                          });
                  }

          },
          filter: function(evt) {
              evt = (evt) ? evt : window.event;
              let expect = evt.target.value.toString() + evt.key.toString();

              if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                  evt.preventDefault();
              } else {
                  return true;
              }
          },
      },
      computed: {
          btnText () {
              if (this.fields) {
                  return 'Оценка'
              }
              else {
                  return "Анализировать"
              }
          }
      },
      watch: {
          importProductsDialog () {
              if (!this.importProductsDialog) {
                  this.fields = null;
                  this.columns = null;
                  this.searchFields = [];
                  this.selectedFields = {};
                  this.files = null;
              }
          },
          editSkuDialog (val) {
              if (!val) {
                  this.skuId = null;
                  this.skuColor = null;
                  this.skuSize = null;
                  this.skuBarcode = {};
                  this.$refs.sku.resetValidation();
              }
          },
          skuBarcodes (val) {
              let ret = [{text: 'ID', value: 'id'}, {text: 'Цвет', value: 'color'}, {text: 'Размер', value: 'size'}];
              if (val)
              for (var i in val) {
                  ret.push({text: val[i].title, value:'barcode_'+val[i].id});
              }
              ret.push({text: 'Действия', value:'actions'});
              this.headerSku = ret;
          },
          tablePrefDialog (val) {
              if (!val) {
                this.headers = this.tmpheaders.h1;
                this.avalHeaders = this.tmpheaders.h2;
              }
          },
          editProductDialog (val) {
              if (!val) {
                  this.closeNewProduct();
              }
          },
          editSkuPropDialog(val) {
              if (!val) {
                  this.skuPropTitle = '';
                  this.skuPropSort = '100';
                  this.skuPropItemId = null;
                  this.$refs.skuPropForm.resetValidation();
              }
          },
          SKUPropsDialog (val) {
              if (val) {
                if (this.skuPropsTable === 'colors') {
                    this.skuPropItems = this.skuColors;
                }
                else if (this.skuPropsTable === 'sizes') {
                      this.skuPropItems = this.skuSizes;
                  }
                else if (this.skuPropsTable === 'barcodes') {
                      this.skuPropItems = this.skuBarcodes;
                  }
              }
          },
          skuDialog (val) {
              if (val) {
                  this.$nextTick(function() {
                      let $this = this;
                      $this.progress = true;
                      axios({
                      url: '/api/sku/get',
                      params: {pid: this.skuProd.id},
                      method: 'GET'
                  }).then(function (response) {
                      if (response.data.skus) {
                          $this.skuColors = response.data.colors;
                          $this.skuSizes = response.data.sizes;
                          $this.skuBarcodes = response.data.barcodes;
                          $this.skus = response.data.skus;
                          $this.progress = false;
                      }
                      else if (response.data.error) {
                          $this.errorMessage = response.data.error;
                          $this.overlay = true;
                          $this.progress = false;
                      }
                  })
                      .catch(function () {
                          $this.errorMessage = 'Ошибка';
                          $this.overlay = true;
                          $this.progress = false;
                      });
                  });

              }
          }
      },
      mounted() {
          let $this = this;
          $this.progress = true;

          axios({
              method: 'GET',
              url: '/api/feature/get?t=products'
          }).then(function (response) {
              if (response.data.features) {
                  $this.features = response.data.features;
                  $this.headers = response.data.headers;
                  $this.avalHeaders = response.data.avalHeaders;
                  $this.progress = false;
              }
              else if (response.data.error) {
                  $this.errorMessage = response.data.error;
                  $this.overlay = true;
                  $this.progress = false;
              }
          })
              .catch(function () {
                  $this.errorMessage = 'Ошибка';
                  $this.overlay = true;
                  $this.progress = false;
              });

          this.refresh();
      }
  }
</script>
